import React, { useState, useEffect } from 'react';
import footercall from '../../Images/footer-call.png';

function FooterInput() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [err, setErr] = useState({});
    const [err2, setError2] = useState({});
    const [isPhoneNumberAvailable, setIsPhoneNumberAvailable] = useState(true);

    useEffect(() => {
        const checkPhoneNumberAvailability = async () => {
            try {
                const response = await fetch('https://apnachaiwala.onrender.com/publicdata/checkPhoneNumber', {
                    method: 'POST',
                    body: JSON.stringify({ MobileNum: phone }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setIsPhoneNumberAvailable(!result.exists);
            } catch (error) {
                console.error('Error checking phone number:', error);
                // Handle error as needed
            }
        };

        if (phone.length === 10) {
            checkPhoneNumberAvailability();
        }
    }, [phone]);

    const handlePhoneChange = (e) => {
        let phoneNumber = e.target.value;
        phoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 10);
        if (phoneNumber.length > 0 && !/^[6-9]/.test(phoneNumber)) {
            phoneNumber = phoneNumber.slice(1);
        }
        if (hasConsecutiveRepeats(phoneNumber, 5)) {
            setPhone('');
            setError2((prevErr) => ({ ...prevErr, phone: 'Consecutive repeated numbers multiple times not allowed.' }));
        } else {
            setPhone(phoneNumber);
            setErr((prevErr) => ({ ...prevErr, phone: '' }));
            setError2((prevErr) => ({ ...prevErr, phone: '' }));
        }
    };

    const handleNameChange = (e) => {
        const inputName = e.target.value;
        if (/[^a-zA-Z\s]/.test(inputName)) {
            setErr((prevErr) => ({ ...prevErr, name: 'Name should only contain letters and spaces.' }));
            return;
        }
        const formattedName = inputName
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        const trimmedName = formattedName.slice(0, 50);
        setName(trimmedName);
        setErr((prevErr) => ({ ...prevErr, name: '' }));
    };

    const hasConsecutiveRepeats = (str, maxOccurrences) => {
        const consecutiveRegex = /(\d)\1{0,}/g;
        const matches = str.match(consecutiveRegex) || [];
        return matches.some((match) => match.length > maxOccurrences);
    };

    const handleCallSubmit = async (e) => {
        e.preventDefault();

        // Validation code for name and phone number
        const validationErrors = {};
        const validationErrors2 = {};
        if (!name) {
            validationErrors.name = 'Please fill the input';
        }
        if (!phone) {
            validationErrors.phone = 'Please fill the input';
        } else if (phone.replace(/\D/g, '').length !== 10 || !/^[6-9]/.test(phone)) {
            validationErrors2.phone = 'Please enter a valid 10-digit number starting with 6, 7, 8, or 9';
        } else if (!isPhoneNumberAvailable) {
            console.log('Mobile number already exists. Not submitting the form. isPhoneNumberAvailable:', isPhoneNumberAvailable);
            setError2((prevErr) => ({ ...prevErr, phone: 'Mobile number already exists' }));
            return;
        } else {
            // Form submission logic
            try {
                const formResponse = await fetch('https://apnachaiwala.onrender.com/publicdata/footerFormData', {
                    method: 'POST',
                    body: JSON.stringify({ Name: name, MobileNum: phone }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!formResponse.ok) {
                    throw new Error('Network response was not ok');
                }

                const formResult = await formResponse.json();
                console.log('Form submitted successfully:', formResult);
                alert('Form submitted successfully!');
            } catch (formError) {
                console.error('Error submitting form:', formError);
                // Handle error as needed
            }

            // Clear inputs after successful submission
            setName('');
            setPhone('');
        }

        // Update state with validation errors
        setErr(validationErrors);
        setError2(validationErrors2);
    };

    return (
        <>
            <div className="container-fluid pt-5">
                <div className="container-xl">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-4 col-md-4 col-sm-6 footer-form">
                            <input
                                type="text"
                                placeholder="ENTER YOUR NAME"
                                onChange={handleNameChange}
                                value={name}
                            />
                            {err.name ? <span style={{ color: '#fff' }}>{err.name}</span> : null}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 footer-form">
                            <input
                                type="tel"
                                placeholder="ENTER PHONE NUMBER"
                                onChange={handlePhoneChange}
                                value={phone}
                            />
                            {err.phone ? <span style={{ color: '#fff' }}>{err.phone}</span> : null} <br />
                            {(phone.length > 0 && phone.length < 10) && (
                                <div style={{ color: '#fff' }}>Number of digits: {phone.length}</div>
                            )}
                            {err2.phone ? <span style={{ color: '#fff' }}>{err2.phone}</span> : null}
                        </div>
                        <div className="col-lg-4 col-md-4 footer-form">
                            <div className="row align-items-start justify-content-evenly footer-text">
                                <h3 onClick={handleCallSubmit}>
                                    <img src={footercall} alt="" width={40} />&nbsp;&nbsp;&nbsp;
                                    Get A Call Back
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterInput;