import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Banner from './Banner';
import Graph from './Graph';
import MissionBox from './MissionBox';
import SmallImageSrc from '../../Images/ACW Mascot.png';
import { Link,useNavigate } from 'react-router-dom';
function Home() {
  const [showPopup, setShowPopup] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate('/franchise');
    setTimeout(() => {
      document.getElementById('franchise-input-section').scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const notificationTimeout = setTimeout(() => {
      setShowNotification(true);
    }, 1000);
    return () => {
      clearTimeout(notificationTimeout);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Header />
      <Banner />
      <Graph />
      <MissionBox />
      <Footer />
      {showPopup && (
        <div ref={popupRef} className="popup popup-animation">
          <div className="popup-content">
            <span className="close-btn" onClick={closePopup}>
              ×
            </span>
            <div onClick={handleImageClick}><img src={SmallImageSrc} alt="Small Image" /></div>

            {showNotification && (
              <div className="notification" style={{ transform: 'translateX(-50%)' }}>
           <div onClick={handleImageClick} style={{ textDecoration: 'none',  }}>Get Franchise
                      </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
