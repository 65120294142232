import React from 'react'
import { useNavigate } from 'react-router-dom';
import aboutcup from '../../Images/Kulhad in hand 1.png'
import getMenu from '../../Images/menu.png'
import getFranchise from '../../Images/franchiseSide.png'
function AboutHome() {
    const navigate = useNavigate();
    const handleImageClick = () => {
        navigate('/menuItem');
      };
      const handleFranchiseClick = () => {
        navigate('/franchise');
        setTimeout(() => {
          document.getElementById('franchise-input-section').scrollIntoView({ behavior: 'smooth' });
        }, 500);
      };
    return (
        <>
        <img
        src={getMenu}
        alt=''
        className='rightSideGetFranchise'
        onClick={handleImageClick} 
      />
       <img
        src={getFranchise}
        alt=''
        className='rightSideGetFranchises'
        onClick={handleFranchiseClick}
      />
            <div className="container-fluid home-about">
                <div className="container-xl">
                <div className="row align-items-center">
                        <div className="col-lg-8 col-md-7 about-home-text">
                            <h2>About</h2>
                            <h3>Apna Chai Wala</h3>
                            <p>Apna Chai Wala started outside a university in Jalandhar with a very small shop that served the best kulhad chai to students at a reasonable price.
                                The place became an instant hit and this resulted in a chain of cafés.</p>
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <img src={aboutcup} alt="" className='img-fluid about' loading='lazy'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutHome