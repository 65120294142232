import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Style/Style.css';
import './Style/Responsive.css';
import Home from "./Pages/Home/Home";
import Loder from './Pages/loader/Loader';

const About = React.lazy(() => import("./Pages/About/About"));
const Franchise = React.lazy(() => import("./Pages/Franchise/Franchise"));
const Career = React.lazy(() => import("./Pages/Careers/Career"));
const PrivacyPolicy = React.lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const TermsConditions = React.lazy(() => import("./Pages/TermsCondition/TermsConditions"));
const Blog = React.lazy(() => import("./Pages/Blogs/Blog"));
const AdminPortal = React.lazy(() => import("./Pages/AdminPage/AdminPortal"));
const Dashboard = React.lazy(() => import("./Pages/AdminPage/Dashboard"));
const FranchiseData = React.lazy(() => import("./Pages/AdminPage/FranchiseData"));
const QueryList = React.lazy(() => import("./Pages/AdminPage/FooterQuery"));
const ChangePassword = React.lazy(() => import("./Pages/AdminPage/changePassword"));
const ForgetPasswordForm = React.lazy(() => import('./Pages/AdminPage/ForgetPassword'));
const ResetPasswordForm = React.lazy(() => import('./Pages/AdminPage/ResetPassword'));
const FAQs = React.lazy(() => import('./Pages/FAQs/faqs'));
const SubAdminProfile = React.lazy(() => import('./Pages/subAdimn/profilecreate'));
const SubAdminList = React.lazy(() => import('./Pages/subAdimn/subAdminlistRender'));
const SubAdminData = React.lazy(() => import("./Pages/subAdimn/FranchiseDataApplication"));
const SubAdminQuery = React.lazy(() => import("./Pages/subAdimn/FooterQuerys"));
const GetSubAdminDetails = React.lazy(() => import("./Pages/subAdimn/subAdminGetById"));
const SubAdminDashboard = React.lazy(() => import("./Pages/subAdimn/subAdminDashboard"));
const SubChangePassword = React.lazy(() => import("./Pages/subAdimn/changePassword"));
const SubForgetPasswordForm = React.lazy(() => import('./Pages/subAdimn/ForgetPassword'));
const SubResetPasswordForm = React.lazy(() => import('./Pages/subAdimn/ResetPassword'));
const MenuItem = React.lazy(() => import('./Pages/menuItem/menuItem'));
const CareerData = React.lazy(() => import('./Pages/AdminPage/careerFormData'));
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/loader" element={<Loder/>}/>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loder />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loder />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/franchise"
          element={
            <Suspense fallback={<Loder />}>
              <Franchise />
            </Suspense>
          }
        />
        <Route
          path="/career"
          element={
            <Suspense fallback={<Loder />}>
              <Career />
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<Loder />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/terms-conditions"
          element={
            <Suspense fallback={<Loder />}>
              <TermsConditions />
            </Suspense>
          }
        />
        <Route
          path="/blog"
          element={
            <Suspense fallback={<Loder />}>
              <Blog />
            </Suspense>
          }
        />
        <Route
          path="/admin-login"
          element={
            <Suspense fallback={<Loder />}>
              <AdminPortal />
            </Suspense>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<Loder />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="/franchiseData"
          element={
            <Suspense fallback={<Loder />}>
              <FranchiseData />
            </Suspense>
          }
        />
        <Route
          path="/query-list"
          element={
            <Suspense fallback={<Loder />}>
              <QueryList />
            </Suspense>
          }
        />
        <Route
          path="/changePassword"
          element={
            <Suspense fallback={<Loder />}>
              <ChangePassword />
            </Suspense>
          }
        />
        <Route
          path="/forget-password"
          element={
            <Suspense fallback={<Loder />}>
              <ForgetPasswordForm />
            </Suspense>
          }
        />
        <Route
          path="/reset-password/:resetToken"
          element={
            <Suspense fallback={<Loder />}>
              <ResetPasswordForm />
            </Suspense>
          }
        />
        <Route
          path="/faqs"
          element={
            <Suspense fallback={<Loder />}>
              <FAQs />
            </Suspense>
          }
        />
        <Route
          path="/subAdmin"
          element={
            <Suspense fallback={<Loder />}>
              <SubAdminProfile />
            </Suspense>
          }
        />
        <Route
          path="/subAdminList"
          element={
            <Suspense fallback={<Loder />}>
              <SubAdminList />
            </Suspense>
          }
        />
        <Route
          path="/SubAdminData"
          element={
            <Suspense fallback={<Loder />}>
              <SubAdminData />
            </Suspense>
          }
        />
        <Route
          path="/SubAdminQuery"
          element={
            <Suspense fallback={<Loder />}>
              <SubAdminQuery />
            </Suspense>
          }
        />
        <Route
          path="/subAdminDashboard"
          element={
            <Suspense fallback={<Loder />}>
              <SubAdminDashboard />
            </Suspense>
          }
        />
        <Route
          path={`/getDetails`}
          element={
            <Suspense fallback={<Loder />}>
              <GetSubAdminDetails />
            </Suspense>
          }
        />
        <Route
          path="/changePasswords"
          element={
            <Suspense fallback={<Loder />}>
              <SubChangePassword />
            </Suspense>
          }
        />
        <Route
          path="/forgetPasswords"
          element={
            <Suspense fallback={<Loder />}>
              <SubForgetPasswordForm />
            </Suspense>
          }
        />
        <Route
          path="/resetPasswords/:resetToken"
          element={
            <Suspense fallback={<Loder />}>
              <SubResetPasswordForm />
            </Suspense>
          }
        />
        <Route
          path="/menuItem"
          element={
            <Suspense fallback={<Loder />}>
              <MenuItem />
            </Suspense>
          }
        />
        <Route
          path="/careerData"
          element={
            <Suspense fallback={<Loder />}>
              <CareerData />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
