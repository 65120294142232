import React, { useState, useEffect, useRef } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import SmallImageSrc from '../../Images/ACW Mascot.png';

function FooterNav() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const popupRef = useRef(null);
  const handleImageClick = () => {
    navigate('/franchise');
    setTimeout(() => {
      document.getElementById('franchise-input-section').scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };
  useEffect(() => {
    const hasPopupBeenShown = localStorage.getItem('popupShown');
    if (!hasPopupBeenShown) {
      setShowPopup(true);
      localStorage.setItem('popupShown', 'true');
    }
    const handleScroll = () => {
      const footerOffset = document.getElementById('footer').getBoundingClientRect().top;
      setIsScrolled(footerOffset < window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const notificationTimeout = setTimeout(() => {
      setShowNotification(true);
    }, 1000);
  
    return () => {
      clearTimeout(notificationTimeout);
    };
  }, []);
  const closePopup = () => {
    setShowPopup(false);
  };

  const scrollToHome = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>

      <div className="container-fluid">
        <div className="container-xl">
          <div className="row">
            <div className="footer-nav">
              <Link onClick={scrollToHome} to='/privacy-policy'><h2>Privacy Policy</h2></Link>
              <Link onClick={scrollToHome} to='/terms-conditions'><h2>Terms and Conditions</h2></Link>
              <Link onClick={scrollToHome} to='/faqs'><h2>FAQs</h2></Link>
              <Link onClick={scrollToHome} to='/blog'><h2>Blog</h2></Link>
            </div>
          </div>
          <div className="footer-social">
            <div className='footer-border-right'><Link target="_blank" to='https://www.facebook.com/apnachaiwala1/'><i className="fa-brands fa-facebook-f"></i></Link></div>
            <div className='footer-border-right'><Link target="_blank" to='https://www.instagram.com/apna_chaiwala/'><i className="fa-brands fa-instagram"></i></Link></div>
            <div> <Link target="_blank" to='https://www.youtube.com/@APNACHAIWALA'><i className="fa-brands fa-youtube"></i></Link></div>
            {showPopup && (
              <div ref={popupRef} className="popup popup-animation">
                <div className="popup-content">
                  <span className="close-btn" onClick={closePopup}>
                    ×
                  </span>
                  <div  onClick={handleImageClick}><img src={SmallImageSrc} alt="Small Image" /></div>

                  {showNotification && (
                    <div className="notification" style={{ transform: 'translateX(-50%)' }}>
                      <div  onClick={handleImageClick} style={{ textDecoration: 'none'}}>Get Franchise
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`container-fluid${isScrolled ? ' scrolled' : ''}`} id="footer">
        <div className="border1"></div>
        <div className="row footer-copyright">
          <h3><span>© 2023 Apna Chai Wala.</span> All rights reserved.</h3>
        </div>
        <div className="border1"></div>
      </div>
    </>
  )
}

export default FooterNav





// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Kulhad from '../../Images/Kulhad@2x 1.png';
// import FranchiseApplication from '../Franchise/FranchiseInput';

// function FooterNav() {
//     const [isImageVisible, setIsImageVisible] = useState(true);
//     const [showFranchiseApplication, setShowFranchiseApplication] = useState(false);
//     const navigate = useNavigate();

//     const scrollToHome = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth',
//         });
//     };

//     const toggleImageVisibility = () => {
//         setIsImageVisible(!isImageVisible);
//         setShowFranchiseApplication(true); // Add any other logic here
//         navigate('/franchise');
//     };

//     return (
//         <>
//             <div className="container-fluid">
//                 <div className="container-xl">
//                     <div className="row">
//                         <div className="footer-nav">
//                             <Link onClick={scrollToHome} to="/privacy-policy">
//                                 <h2>Privacy Policy</h2>
//                             </Link>
//                             <Link onClick={scrollToHome} to="/terms-conditions">
//                                 <h2>Terms and Conditions</h2>
//                             </Link>
//                             <Link onClick={scrollToHome} to="/faqs">
//                                 <h2>FAQs</h2>
//                             </Link>
//                             <Link onClick={scrollToHome} to="/blog">
//                                 <h2>Blog</h2>
//                             </Link>
//                         </div>
//                     </div>
//                     <div className="footer-social">
//                         <div className="footer-border-right">
//                             <Link target="_blank" to="https://www.facebook.com/apnachaiwala1/">
//                                 <i className="fa-brands fa-facebook-f"></i>
//                             </Link>
//                         </div>
//                         <div className="footer-border-right">
//                             <Link target="_blank" to="https://www.instagram.com/apna_chaiwala/">
//                                 <i className="fa-brands fa-instagram"></i>
//                             </Link>
//                         </div>
//                         <div>
//                             <Link target="_blank" to="https://www.youtube.com/@APNACHAIWALA">
//                                 <i className="fa-brands fa-youtube"></i>
//                             </Link>
//                         </div>
//                     </div>
//                     {isImageVisible && (
//                         <div style={{ position: 'relative', textAlign: 'right' }}>
//                             <div
//                                 style={{
//                                     position: 'absolute',
//                                     top: -5,
//                                     right: 6,
//                                     cursor: 'pointer',
//                                     padding: '1px',
//                                     color: '#fff',
//                                 }}
//                                 onClick={toggleImageVisibility}
//                             >
//                                 X
//                             </div>
//                             <img
//                                 src={Kulhad}
//                                 alt=" "
//                                 style={{ maxWidth: '100%', height: '60px' }}
//                                 onClick={toggleImageVisibility}
//                             />
//                         </div>
//                     )}
//                 </div>
//             </div>
//             <div className="container-fluid">
//                 <div className="border1"></div>
//                 <div className="row footer-copyright">
//                     <h3>
//                         <span>© 2023 Apna Chai Wala.</span> All rights reserved.
//                     </h3>
//                 </div>
//                 <div className="border1"></div>
//             </div>

//             {showFranchiseApplication && <FranchiseApplication />}
//         </>
//     );
// }

// export default FooterNav;
