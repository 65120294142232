import React, { useState } from 'react';
import mainlogo from './../../Images/main-logo.png';
import { Link } from 'react-router-dom';

function Navbar() {
    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");

    const scrollToHome = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setActive("nav__menu"); // Close the menu on link click
        setIcon("nav__toggler");
    };

    const navToggle = () => {
        setActive(active === "nav__menu" ? "nav__menu nav__active" : "nav__menu");
        setIcon(icon === "nav__toggler" ? "nav__toggler toggle" : "nav__toggler");
    };

    return (
        <div className="">
            <nav className="nav">
                <a href="/" className="nav__brand">
                    <img
                        src={mainlogo}
                        alt=""
                        className='img-fluid'
                    />
                </a>
                <ul className={active}>
                    <li className="nav__item">
                        <Link to='/about' onClick={scrollToHome} className="nav__link navLink2" style={{ textDecoration: 'none' }}>About us</Link>
                    </li>
                    <li className="nav__item">
                        <Link to='/career' onClick={scrollToHome} className="nav__link navLink" style={{ textDecoration: 'none' }}>Career</Link>
                    </li>
                    <li className="nav__item">
                        <a href="#" className="nav__link navLink" style={{ textDecoration: 'none' }}>Media</a>
                    </li>
                    <li className="nav__item">
                    <a href="/franchise" className="nav__link navLink headerButton" style={{ textDecoration: 'none' }}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Franchise</a>
                    </li>
                    <li className="nav__item">
                        <a href="#" className="nav__link navLink" style={{ textDecoration: 'none' }}></a>
                    </li>
                    <li className="nav__item">
                        <a href="#" className="nav__link navLink" style={{ textDecoration: 'none' }}></a>
                    </li>
                    <li className="nav__item">
                        <a href="#" className="nav__link navLink" style={{ textDecoration: 'none' }}></a>
                    </li>
                </ul>
                <div onClick={navToggle} className={icon}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;