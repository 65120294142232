import React from 'react';
import './CoffeeCupAnimation.css';
import loader from '../../Images/outline.png'
import outlet1 from '../../Images/outline1.png'
import outlet2 from '../../Images/outline2.png'
import outlet3 from '../../Images/outline3.png'
const CoffeeCupAnimation = () => {
  return (
    <div className="abcs container">
      <div className='mainLoader'>
       <div className='innerLoader'>
       <img src={outlet1} alt=''/>
        <img src={outlet2} alt=''/>
        <img src={outlet3} alt=''/>
       </div>
       <div className="mainPartLoader">
        <img src={loader} alt='loader' />
        </div>
      </div>
    </div>
  );
};

export default CoffeeCupAnimation;
